export default {
    login: {
        login: '登录',
        userName: '您的邮箱',
        password: '登录密码',
        register: '注册',
        alLogin: '已有账号，点击登录'
    },
    user:{
        today: '今天',
        booking_detail: '预约详情',
        booking_project: '服务项目',
        booking_date: '选择日期',
        booking_time: '选择时间段',
        booking_no_time: '当前日期无时间段可预约',
        booking_tip: '已预约时间段不显示',
        booking_people: '选择接洽人',
        booking_people_tip: '左右滑动选择人员',
        booking_no_people: '当前无人员可预约',
        booking_btn: '确认',
        booking_email: '请留下您的邮箱',
        booking_sync_gmail: '支持同步至Google日历',
        booking_user: '怎么称呼您',
        booking_commit: '备注',
        booking_result_tip: '您的预约已提交成功!',
        booking_result_msg: '以下是您的预约信息!',
        booking_result_sync: '同步到 Google 日历!',

    },
    staff:{
        today: '今天',
        today_tip: '今天',
        week_tip: '今天',
        email: '您的邮箱',
        password: '您的邮箱',
        login_btn: '登录',
        service: '服务项目',
        change_email: '服务项目',
        update_pwd: '修改密码',
        new_pwd: '服务项目',
        com_pwd: '服务项目',
        commit_title: '预约备忘',
        commit_desc: '可以在此记录预约相关信息'

    },
    company: {
        users : '人员列表',
        tips_pre: '共有',
        tips_end: '件预约',
        service: '服务条款',
        yinsi: '隐私政策',
        setting_title: '系统设置',
        setting_company_name: '公司或品牌名称',
        setting_area: '所在地区',
        setting_location: '详细地址',
        setting_phone: '联系电话',
        setting_code: '客户入口代码',
        setting_service: '服务设置',
        setting_service_name: '服务名称',
        setting_service_add: '添加',
        setting_service_list: '服务列表',
        setting_booking_title: '预约设置',
        setting_booking_date: '日期范围',
        setting_booking_time: '时间范围',
        setting_booking_unit: '时间单位',
        home_tip1: '点击切换人员列表',
        home_tip2: '点击加载所有人员预约事件',
        home_tip3: '点击添加人员',
        home_code: '客户端二维码',

    }
};