export default {
    login: {
        login: 'login',
        userName: 'username',
        password: 'password',
        register: 'Register',
        alLogin: 'have an account'
    },
    user:{
        today: 'Today',
        booking_detail: 'Booking Detail',
        booking_project: 'Services',
        booking_date: 'Select date',
        booking_time: 'Select time',
        booking_no_time: 'No available time slot for booking.',
        booking_tip: 'The reserved time period is not displayed',
        booking_people: 'Select receptionist',
        booking_people_tip: 'Slide to show more',
        booking_no_people: 'No personel available',
        booking_btn: 'Confirm',
        booking_email: 'Please leave your email address',
        booking_sync_gmail: 'Support syncing to Google Calendar',
        booking_user: 'Your name',
        booking_commit: 'Notes',
        booking_result_tip: 'Your appointment has been successfully submitted!',
        booking_result_msg: 'Here is your appointment information!',
        booking_result_sync: 'Sync to Google Calendar!',
    },
    staff:{
        today: 'Today',
        today_tip: 'Today',
        week_tip: 'This Week',
        email: 'Email',
        password: 'Password',
        login_btn: 'Login',
        service: 'Services',
        change_email: 'Change Password',
        update_pwd: 'Change Password',
        new_pwd: 'New Password',
        com_pwd: 'Com Password',
        commit_title: 'Commit',
        commit_desc: '可以在此记录预约相关信息'
    },
    company: {
        users : 'User List',
        tips_pre: 'There are a total of ',
        tips_end: 'appointments available',
        service: 'Service Terms',
        yinsi: 'Privacy Policy',
        setting_title: 'Setting',
        setting_company_name: 'Your Company Name',
        setting_area: 'Zone',
        setting_location: 'Address',
        setting_phone: 'Phone',
        setting_code: 'Website embedding code',
        setting_service: 'Service',
        setting_service_name: 'Service Name',
        setting_service_add: 'Add',
        setting_service_list: 'Service List',
        setting_booking_title: 'Appointment',
        setting_booking_date: 'Date',
        setting_booking_time: 'Time',
        setting_booking_unit: 'Duration',
        home_tip1: 'View blocked staff',
        home_tip2: 'View all appointments',
        home_tip3: 'Add new staff',
        home_code: 'Client code',

    }
};